<h1 mat-dialog-title class="title">Edycja kosztów</h1>
<hr class="custom-hr" />
<div mat-dialog-content class="mat-dialog-content" [formGroup]="formCosts">
  <p class="p-content">Koszt transportu</p>
  <div class="did-floating-label-content full-width">
    <input
      class="did-floating-input"
      formControlName="courier_cost"
      type="number"
      placeholder=" " />
    <label class="did-floating-label">Koszt kuriera(netto)</label>
  </div>
  <div class="did-floating-label-content full-width">
    <input
      class="did-floating-input"
      formControlName="service_cost"
      type="number"
      placeholder=" " />
    <label class="did-floating-label">Koszt obsługi(netto)</label>
  </div>
  <p class="p-content">Całkowity koszt ekspertyzy</p>
  <div class="did-floating-label-content full-width">
    <input
      class="did-floating-input"
      formControlName="consultation_cost"
      type="number"
      placeholder=" " />
    <label class="did-floating-label">Koszt konsultacji(netto)</label>
  </div>
  <div class="did-floating-label-content full-width">
    <input
      class="did-floating-input"
      formControlName="replacement_cost"
      type="number"
      placeholder=" " />
    <label class="did-floating-label">Koszt wymiany(netto)</label>
  </div>
  <p class="p-content">Pozostałe koszty</p>
  <div class="did-floating-label-content full-width">
    <input
      class="did-floating-input"
      formControlName="labor_cost"
      type="number"
      placeholder=" " />
    <label class="did-floating-label">Koszt robocizny(netto)</label>
  </div>
  <div class="did-floating-label-content full-width">
    <input
      class="did-floating-input"
      formControlName="own_share"
      type="text"
      placeholder="Udział własny wyliczy się automatycznie" />
    <label class="did-floating-label">Udział własny</label>
  </div>
  <section class="example-section">
    <mat-checkbox formControlName="synchro" class="example-margin"
      >Synchronizuj z klientem</mat-checkbox
    >
  </section>
  <mat-error *ngIf="formCosts.invalid" class="error">
    Wszystkie pola muszą byc wypełnione.
  </mat-error>
  <mat-error *ngIf="error"> {{ error }}</mat-error>
  <div mat-dialog-actions>
    <button
      mat-button
      class="secondary-btn"
      color="accent"
      [mat-dialog-close]="'cancel'">
      {{ 'orders-front.cancel' | translate }}
    </button>
    <button mat-button class="primary-btn" (click)="onSubmit()" color="primary">
      {{ 'orders-front.add' | translate }}
    </button>
  </div>
</div>
