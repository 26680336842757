<h1 mat-dialog-title class="title">Dodaj preautoryzację</h1>
<hr class="custom-hr" />
<div mat-dialog-content>
  <form [formGroup]="preauthForm" (ngSubmit)="onSubmit()">
    <div class="did-floating-label-content w-100 mt-15">
      <input
        class="did-floating-input"
        id="code"
        formControlName="code"
        type="text" />
      <label class="did-floating-label" for="code">Kod</label>
      <mat-error
        *ngIf="
          preauthForm.get('code').invalid && preauthForm.get('code').touched
        ">
        Kod jest wymagany.
      </mat-error>
    </div>

    <div class="did-floating-label-content w-100 mt-15">
      <input
        class="did-floating-input"
        id="action"
        formControlName="action"
        type="text" />
      <label class="did-floating-label" for="action">Akcja</label>
      <mat-error
        *ngIf="
          preauthForm.get('action').invalid && preauthForm.get('action').touched
        ">
        Akcja jest wymagana.
      </mat-error>
    </div>

    <div class="did-floating-label-content w-100 mt-15">
      <input
        class="did-floating-input"
        id="actionCode"
        formControlName="actionCode"
        type="text" />
      <label class="did-floating-label" for="actionCode">Kod akcji</label>
      <mat-error
        *ngIf="
          preauthForm.get('actionCode').invalid &&
          preauthForm.get('actionCode').touched
        ">
        Kod alkcji jest wymagany.
      </mat-error>
    </div>

    <div class="did-floating-label-content w-100 mt-15">
      <input
        class="did-floating-input"
        id="estimatedCost"
        formControlName="estimatedCost"
        type="number"
        step="0.01" />
      <label class="did-floating-label" for="estimatedCost"
        >Szacowany koszt</label
      >
      <div
        *ngIf="
          preauthForm.get('estimatedCost').invalid &&
          preauthForm.get('estimatedCost').touched
        ">
        <mat-error *ngIf="preauthForm.get('estimatedCost').errors.required">
          Szacowany koszt jest wymagany.
        </mat-error>
        <mat-error *ngIf="preauthForm.get('estimatedCost').errors.pattern">
          Szacowany koszt musi być liczbą.
        </mat-error>
      </div>
    </div>

    <div class="did-floating-label-content w-100 mt-15">
      <textarea
        class="did-floating-input"
        id="diagnosis"
        formControlName="diagnosis"
        type="text"></textarea>
      <label class="did-floating-label" for="diagnosis">Diagnoza</label>
      <mat-error
        *ngIf="
          preauthForm.get('diagnosis').invalid &&
          preauthForm.get('diagnosis').touched
        ">
        Diagnoza jest wymagana.
      </mat-error>
    </div>
  </form>
</div>
<div mat-dialog-actions>
  <button
    mat-button
    class="secondary-btn"
    color="accent"
    [mat-dialog-close]="'cancel'">
    {{ 'cancel' | translate }}
  </button>
  <button mat-button class="primary-btn" (click)="onSubmit()" color="primary">
    {{ 'Save' | translate }}
  </button>
</div>
+
