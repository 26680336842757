import { Component, Input, SimpleChanges } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { EditMarginInOrderComponent } from 'src/app/shared/modals/edit-margin-in-order/edit-margin-in-order.component';
import { OrdersService } from '../services/orders.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ShowPreAuthComponent } from '../../../shared/modals/show-preauth/show-preauth.component';
import { AuthGuard } from 'src/app/shared/guards/access-allowed.guard';

@Component({
  selector: 'app-order-info',
  templateUrl: './order-info.component.html',
  styleUrls: ['./order-info.component.scss'],
})
export class OrderInfoComponent {
  @Input() order: any;
  public marginId!: string;
  public margin: any;
  public openPre: any;
  public marginLoaded = false;
  constructor(
    private dialog: MatDialog,
    private orderService: OrdersService,
    private route: ActivatedRoute,
    private router: Router,
    public Auth: AuthGuard
  ) {
    const navigationState = this.router.getCurrentNavigation()?.extras?.state;
    this.openPre = navigationState ? navigationState : null;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.order.currentValue) {
      this.orderService.getOrderPreAuth(this.order.id).subscribe(res => {
        this.order.preauthorization = res;
        if (this.openPre) {
          this.openPreAuth();
        }
      });
      if (this.order.workshop) {
        this.order.workshopId = this.order.workshop.split('/')[5];
        this.marginId = this.marginId = this.order.margin.split('/')[5];
        if (this.Auth.isViewAllowed(['ROLE_ORDER_MARGIN_VIEW', 'ROLE_ADMIN'])) {
          this.orderService.getOrderMargin(this.marginId).subscribe(res => {
            this.margin = res;
            this.marginLoaded = true;
          });
        }
      }
    }
  }

  openMargin(): void {
    if (this.marginLoaded) {
      const dialogRef = this.dialog.open(EditMarginInOrderComponent, {
        data: { margin: this.margin, fromOrder: true, state: this.order.state },
        disableClose: true,
      });

      dialogRef.afterClosed().subscribe(result => {
        this.orderService.getOrderMargin(this.marginId).subscribe(res => {
          this.margin = res;
        });
        if (result === 'show') {
          /* this.openMargin(); */
        }
      });
    }
  }

  openPreAuth(): void {
    const dialogRef = this.dialog.open(ShowPreAuthComponent, {
      data: { preAuth: this.order.preauthorization },
      minWidth: '700px',
      height: 'auto',
      disableClose: true,
    });
  }
}
