import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/app/environments/environment';
import { MyType } from 'src/app/shared/types/type-hydra';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root',
})
export class OrdersService {
  constructor(
    private http: HttpClient,
    private translate: TranslateService
  ) {}

  getOrders() {
    return this.http.get(
      environment.API.apiHost +
        environment.API.apiPointInternal +
        `/orders/dashboard/list`
    );
  }

  sendComment(id: string, body: any) {
    return this.http.post(
      environment.API.apiHost +
        environment.API.apiPointInternal +
        `/comments/${id}`,
      body
    );
  }

  getOrdersFiltered(params: any) {
    return this.http.get(
      environment.API.apiHost +
        environment.API.apiPointInternal +
        `/orders/dashboard/list`,
      { params: params }
    );
  }

  getWorkshop(id: string) {
    return this.http.get(environment.API.apiHost + id);
  }

  getOrderById(id: string) {
    return this.http.get(
      environment.API.apiHost +
        environment.API.apiPointInternal +
        `/orders/${id}`
    );
  }

  getOrderHistory(id: string) {
    return this.http.get(
      environment.API.apiHost +
        environment.API.apiPointInternal +
        `/orders/${id}/history/` +
        (this.translate.currentLang || 'pl')
    );
  }

  getAttachments(orderId: any) {
    return this.http.get(
      environment.API.apiHost +
        environment.API.apiPointInternal +
        `/orders/${orderId}/attachments`
    );
  }

  downloadSummary(startDate: any, endDate: any) {
    return this.http.post<Blob>(
      environment.API.apiHost +
        environment.API.apiPointInternal +
        `/report/order-settlement/generate-and-download/${startDate}|${endDate}`,
      {},
      { observe: 'response', responseType: 'blob' as 'json' }
    );
  }

  downloadCostsSummary(startDate: any, endDate: any) {
    return this.http.post<Blob>(
      environment.API.apiHost +
        environment.API.apiPointInternal +
        `/report/order-settlement/costs/generate-and-download/${startDate}|${endDate}`,
      {},
      { observe: 'response', responseType: 'blob' as 'json' }
    );
  }

  getAttachmentTypes() {
    return this.http.get(
      environment.API.apiHost +
        environment.API.apiPointInternal +
        `/attachment_types`
    );
  }

  getAttachment(id: string) {
    return this.http.get(
      environment.API.apiHost +
        environment.API.apiPointInternal +
        `/attachments/${id}/download`,
      { responseType: 'blob' as 'json' }
    );
  }

  getPolicyByName(name: string) {
    return this.http.get(
      environment.API.apiHost +
        environment.API.apiPointInternal +
        `/insurances/by_number/${name}`
    );
  }

  getOrderMargin(id: string) {
    return this.http.get(
      environment.API.apiHost +
        environment.API.apiPointInternal +
        `/order_margins/${id}`
    );
  }

  updateOrderMargin(id: string, body: any) {
    return this.http.put(
      environment.API.apiHost +
        environment.API.apiPointInternal +
        `/order_margins/${id}`,
      body
    );
  }

  getPackages(id: string) {
    return this.http.get(
      environment.API.apiHost +
        environment.API.apiPointInternal +
        `/orders/${id}/shipments`
    );
  }

  addNote(id: string, submit: any) {
    return this.http.put(
      environment.API.apiHost +
        environment.API.apiPointInternal +
        `/orders/${id}/note`,
      {
        content: submit.note,
        type: '',
        code: '',
        toPartner: submit.toPartner,
        toOrderSource: submit.toOrderSource,
      }
    );
  }

  cancelOrder(id: string) {
    return this.http.put(
      environment.API.apiHost +
        environment.API.apiPointInternal +
        `/orders/${id}/cancel`,
      {}
    );
  }

  getBillingList() {
    return this.http.get(
      environment.API.apiHost +
        environment.API.apiPointInternal +
        `/billing/income`
    );
  }
  getBillingListFiltered(params: any) {
    return this.http.get(
      environment.API.apiHost +
        environment.API.apiPointInternal +
        `/billing/income`,
      { params: params }
    );
  }
  getCostsList() {
    return this.http.get(
      environment.API.apiHost +
        environment.API.apiPointInternal +
        `/billing/costs`
    );
  }
  getCostsListFiltered(params: any) {
    return this.http.get(
      environment.API.apiHost +
        environment.API.apiPointInternal +
        `/billing/costs`,
      { params: params }
    );
  }
  getStatesCount() {
    return this.http.get(
      environment.API.apiHost +
        environment.API.apiPointInternal +
        `/orders/states-count`
    );
  }

  quickSearch(params: any) {
    return this.http.get(
      environment.API.apiHost +
        environment.API.apiPointInternal +
        `/orders/quick-search`,
      { params: params }
    );
  }

  postAttachment(data: any, orderId: any) {
    let formData = new FormData();

    formData.append('attachmentFile', data.file);
    formData.append('typeId', data.type);
    formData.append('toOrderSource', data.toOrderSource);
    formData.append('toPartner', data.toPartner);
    return this.http.post(
      environment.API.apiHost +
        environment.API.apiPointInternal +
        `/orders/${orderId}/attachments/upload`,
      formData
    );
  }

  postXlsxToVerify(data: any) {
    let formData = new FormData();

    formData.append('verificationFile', data.file);

    return this.http.post(
      environment.API.apiHost +
        environment.API.apiPointInternal +
        `/billing/verification/upload-xlsx`,
      formData
    );
  }

  postInvoice(data: any, ordersId: any) {
    let formData = new FormData();

    formData.append('invoiceFile', data.file);
    formData.append('orderIds', JSON.stringify(ordersId));
    formData.append('verificationFileId', data.verificationFileId);
    formData.append(
      'verificationFileTotalGross',
      data.verificationFileTotalGross
    );

    return this.http.post(
      environment.API.apiHost +
        environment.API.apiPointInternal +
        `/billing/upload-invoice`,
      formData
    );
  }

  downloadbillingInvoice(orderId: any) {
    return this.http.get(
      environment.API.apiHost +
        environment.API.apiPointInternal +
        `/orders/${orderId}/billing/download`,
      { responseType: 'blob' }
    );
  }
  downloadbillingRevenueInvoice(orderId: any) {
    return this.http.get(
      environment.API.apiHost +
        environment.API.apiPointInternal +
        `/orders/${orderId}/billing-revenue/download`,
      { responseType: 'blob', observe: 'response' }
    );
  }

  getDataFromIRI(iri: string) {
    return this.http.get(environment.API.apiHost + iri);
  }

  getNewComments() {
    return this.http.get(
      environment.API.apiHost +
        environment.API.apiPointInternal +
        '/notifications/unread-comments'
    );
  }

  getNextComments(offset: any, limit: any) {
    return this.http.get(
      environment.API.apiHost +
        environment.API.apiPointInternal +
        `/notifications/unread-comments/${offset}/${limit}`
    );
  }

  getNextNewComments(offset: any, limit: any) {
    return this.http.get(
      environment.API.apiHost +
        environment.API.apiPointInternal +
        `/notifications/unread-comments/${offset}/${limit}?unread-only=yes`
    );
  }

  getNewOrders() {
    return this.http.get(
      environment.API.apiHost +
        environment.API.apiPointInternal +
        '/notifications/unread-orders'
    ) as Observable<MyType>;
  }
  getNewPreAuth() {
    return this.http.get(
      environment.API.apiHost +
        environment.API.apiPointInternal +
        '/notifications/unread-preauthorizations'
    ) as Observable<MyType>;
  }

  getUnreadNotificationsCount() {
    return this.http.get(
      environment.API.apiHost +
        environment.API.apiPointInternal +
        '/notifications/counters'
    );
  }
  getApiState() {
    return this.http.get(
      environment.API.apiHost +
        environment.API.apiPointInternal +
        '/partner_api_requests_logs/status'
    );
  }
  getOrderPreAuth(id: string) {
    return this.http.get(
      environment.API.apiHost +
        environment.API.apiPointInternal +
        `/orders/${id}/preauthorization`
    );
  }

  getStates() {
    return this.http.get(
      environment.API.apiHost +
        environment.API.apiPointInternal +
        '/workflow/places'
    );
  }

  getMaxSize() {
    return this.http.get(
      environment.API.apiHost +
        environment.API.apiPointInternal +
        '/files/upload/max-size'
    );
  }

  getLoggedUser() {
    return this.http.get(
      environment.API.apiHost +
        environment.API.apiPointInternal +
        '/users/logged-user'
    );
  }

  updateServiceForOrder(orderId: any, newWorkshopId: any) {
    return this.http.put(
      environment.API.apiHost +
        environment.API.apiPointInternal +
        '/orders/' +
        orderId +
        '/workshop/' +
        newWorkshopId +
        '/change',
      { '': '' }
    );
  }

  recalculateOrder(orderId: any) {
    return this.http.put(
      environment.API.apiHost +
        environment.API.apiPointInternal +
        '/orders/' +
        orderId +
        '/recalculate-costs',
      { '': '' }
    );
  }

  updateDataOfOrder(orderId: any) {
    return this.http.put(
      environment.API.apiHost +
        environment.API.apiPointInternal +
        '/orders/' +
        orderId +
        '/update-data',
      { '': '' }
    );
  }
  archiveOrder(id: string) {
    return this.http.put(
      environment.API.apiHost +
        environment.API.apiPointInternal +
        `/orders/${id}/move-to-archive`,
      {}
    );
  }

  downloadServiceCostsAndInsurance(startDate: any, endDate: any) {
    return this.http.post<Blob>(
      environment.API.apiHost +
        environment.API.apiPointInternal +
        `/report/order-settlement/service-costs-and-insurance/generate-and-download/${startDate}|${endDate}`,
      {},
      { observe: 'response', responseType: 'blob' as 'json' }
    );
  }

  sendBeginRepair(id: string) {
    return this.http.put(
      environment.API.apiHost +
        environment.API.apiPointInternal +
        `/orders/${id}/repairing`,
      {}
    );
  }

  sendEndRepair(id: string) {
    return this.http.put(
      environment.API.apiHost +
        environment.API.apiPointInternal +
        `/orders/${id}/repaired`,
      {}
    );
  }

  sendFinishOrder(id: string, data: any) {
    return this.http.put(
      environment.API.apiHost +
        environment.API.apiPointInternal +
        `/orders/${id}/finished`,
      data
    );
  }

  assignOrder(id: string, data: any) {
    return this.http.put(
      environment.API.apiHost +
        environment.API.apiPointInternal +
        `/orders/${id}/assigned`,
      data
    );
  }

  fixInvoiceNumber(invoiceNumber: any, invoicePeriodValue: any) {
    return this.http.put(
      environment.API.apiHost +
        environment.API.apiPointInternal +
        `/billing/update-invoice-number-for-period/` +
        invoicePeriodValue,
      {
        invoiceNumber: invoiceNumber,
      }
    );
  }

  sendPreAuth(id: string, data: any) {
    return this.http.put(
      environment.API.apiHost +
        environment.API.apiPointInternal +
        `/orders/${id}/preauthorization`,
      (data = {
        code: data.code,
        action: data.action,
        diagnosis: data.diagnosis,
        actionCode: data.actionCode,

        estimatedCost: data.estimatedCost,
      })
    );
  }

  sendPreAuthDecision(id: string, decision: any) {
    return this.http.put(
      environment.API.apiHost +
        environment.API.apiPointInternal +
        `/orders/${id}/preauthorization-decision/${decision}`,
      {}
    );
  }

  editCosts(id: string, body: any) {
    return this.http.put(
      environment.API.apiHost +
        environment.API.apiPointInternal +
        `/orders/${id}/costs`,
      body
    );
  }

  editParts(id: string, data: any, synchro: any) {
    return this.http.put(
      environment.API.apiHost +
        environment.API.apiPointInternal +
        `/orders/${id}/parts_list?synchro=${synchro}`,
      data
    );
  }

  updatePolicyOfOrder(orderId: any) {
    return this.http.put(
      environment.API.apiHost +
        environment.API.apiPointInternal +
        '/orders/' +
        orderId +
        '/insurance-refresh',
      { '': '' }
    );
  }
}
