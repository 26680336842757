import { OrdersService } from './../../../pages/orders/services/orders.service';
import { Component, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-edit-costs',
  templateUrl: './edit-costs.component.html',
  styleUrls: ['./edit-costs.component.scss'],
})
export class EditCostsComponent {
  order: any;
  toOrderSource: any;
  toPartner: any;
  public formCosts!: FormGroup;
  error: any;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<EditCostsComponent>,
    private ordersService: OrdersService,
    private _snackBar: MatSnackBar,
    private fb: FormBuilder
  ) {
    this.order = data.order;
    console.log(this.order);
    this.formCosts = this.fb.group({
      courier_cost: [this.order.costs.courier, Validators.required],
      service_cost: [this.order.costs.service, Validators.required],
      consultation_cost: [this.order.costs.consultation, Validators.required],
      replacement_cost: [this.order.costs.replacement, Validators.required],
      labor_cost: [this.order.costs.repair, Validators.required],
      own_share: [
        {
          value: this.order.costs.deductibleValueByInsurancePercent,
          disabled: true,
        },
      ],
      synchro: [false],
    });
  }
  onCancel() {
    this.dialogRef.close();
  }

  onSubmit() {
    if (this.formCosts.valid) {
      const data = {
        courier: this.formCosts.value.courier_cost,
        service: this.formCosts.value.service_cost,
        consultation: this.formCosts.value.consultation_cost,
        replacement: this.formCosts.value.replacement_cost,
        repair: this.formCosts.value.labor_cost,
        deductible: -1,
        totalNet: -1,
        totalGross: -1,
        synchro: this.formCosts.value.synchro,
      };
      this.ordersService.editCosts(this.order.id, data).subscribe(
        succes => {
          this._snackBar.open('Koszty zostały zaktualizowane', 'Zamknij', {});
          this.dialogRef.close({
            result: 'success',
          });
        },
        error => {
          console.log(error);
          this.error = error;
        }
      );
    }
  }
}
