<div class="order-details">
  <mat-card class="joined-cards">
    <mat-card class="card">
      <div class="card-title show-data-button"></div>
      <mat-tab-group
        class="no_pointer"
        mat-stretch-tabs="false"
        dynamicHeight
        disableRipple
        disabled>
        <mat-tab label="{{ 'orders-front.order_data' | translate }}">
          <div class="card">
            <div class="card-content">
              <div class="left-site">
                <div class="content-item">
                  <p class="gray">Data zdarzenia</p>
                  <p>
                    {{
                      order?.incident?.eventDate
                        | date: 'YYYY-MM-dd | HH:mm:ss'
                        | dashIfEmpty
                    }}
                  </p>
                </div>
                <hr class="custom-hr" />
                <div class="content-item">
                  <p class="gray">Zlecający</p>
                  <p>{{ order?.orderer | dashIfEmpty }}</p>
                </div>
                <hr class="custom-hr" />
                <div class="content-item">
                  <p class="gray">Sieć</p>
                  <p>{{ order?.network | dashIfEmpty }}</p>
                </div>
                <hr class="custom-hr" />
                <div class="content-item">
                  <p class="gray">Notatka</p>
                  <p>{{ order?.note | dashIfEmpty }}</p>
                </div>
              </div>
              <div class="right-site">
                <div class="content-item">
                  <p class="gray">Kod naprawy</p>
                  <p>{{ order?.repairCode | dashIfEmpty }}</p>
                </div>
                <hr class="custom-hr" />
                <div class="content-item">
                  <p class="gray">Płatnik</p>
                  <p>{{ order?.payer | dashIfEmpty }}</p>
                </div>
                <hr class="custom-hr" />
                <div class="content-item">
                  <p class="gray">Firma</p>
                  <p>{{ order?.company | dashIfEmpty }}</p>
                </div>
                <div class="content-item">
                  <p class="right">
                    <button
                      (click)="addNote()"
                      mat-button
                      *ngIf="
                        Auth.isViewAllowed([
                          'ROLE_ORDER_DETAILS_EDIT',
                          'ROLE_ADMIN',
                        ])
                      ">
                      <mat-icon>create</mat-icon
                      >{{ 'orders-front.edit' | translate }}
                    </button>
                  </p>
                  <p></p>
                </div>
              </div>
            </div>
          </div>
        </mat-tab>
        <mat-tab label="Usterka">
          <div class="card">
            <div class="card-content">
              <div class="single-column">
                <div>
                  <div>
                    <p class="gray">Opis</p>
                    <p>
                      <span>{{
                        order?.incident.description | dashIfEmpty
                      }}</span>
                    </p>
                  </div>
                  <hr class="custom-hr" />
                  <div class="content-item">
                    <p class="gray">Opis stanu / akcesoria</p>
                    <p>
                      <span>{{
                        order?.incident.accessoriesState | dashIfEmpty
                      }}</span>
                    </p>
                  </div>
                  <hr class="custom-hr" />
                  <div class="content-item">
                    <p class="gray">Opis opakowania</p>
                    <p>
                      <span>{{ order?.incident.packaging | dashIfEmpty }}</span>
                    </p>
                  </div>
                  <hr class="custom-hr" />
                </div>
              </div>
            </div>
          </div>
        </mat-tab>
        <mat-tab label="Diagnoza">
          <div class="card">
            <div class="card-content timeline-content diagnosis">
              <div class="content-item col-2w">
                <p class="gray">Opis</p>
                <p class="first-p">
                  {{ order?.preauthorization?.diagnosis }}
                </p>
                <p>
                  {{ order?.preauthorization?.actionCode }} -
                  {{ order?.preauthorization?.action }}
                </p>
                <p>{{ order?.preauthorization?.estimatedCost }}</p>
                <p
                  *ngIf="
                    !order?.preauthorization?.diagnosis &&
                    !order?.preauthorization?.actionCode &&
                    !order?.preauthorization?.action &&
                    !order?.preauthorization?.estimatedCost
                  ">
                  -
                </p>
              </div>
              <div class="right-site">
                <div class="content-item">
                  <p class="gray">Serwis wykonujący</p>
                  <p>
                    <span>{{
                      order?.orderExecution?.repairUnit?.name | dashIfEmpty
                    }}</span>
                  </p>
                </div>
                <div class="content-item">
                  <p class="gray">Data</p>
                  <p>
                    <span>{{
                      order?.dates?.preauthorizationRequestDate
                        | date: 'YYYY-MM-dd | HH:mm:ss'
                        | dashIfEmpty
                    }}</span>
                  </p>
                </div>
              </div>
              <div class="left-site">
                <div class="content-item">
                  <p class="gray">Adress</p>
                  <p>
                    <span *ngIf="order?.orderExecution?.repairUnit?.address">{{
                      order?.orderExecution?.repairUnit?.address?.streetName +
                        ' ' +
                        (order?.orderExecution?.repairUnit?.address
                          ?.buildingNumber
                          ? order?.orderExecution?.repairUnit?.address
                              ?.buildingNumber +
                            (order?.orderExecution?.repairUnit?.address
                              ?.apartmentNumber
                              ? '/' +
                                order?.orderExecution?.repairUnit?.address
                                  ?.apartmentNumber
                              : '')
                          : '') +
                        ' ' | dashIfEmpty
                    }}</span>
                    <br />
                    <span *ngIf="order?.orderExecution?.repairUnit?.address">{{
                      order?.orderExecution?.repairUnit?.address?.postalCode +
                        ' ' +
                        order?.orderExecution?.repairUnit?.address?.city
                        | dashIfEmpty
                    }}</span>
                    <span *ngIf="!order?.orderExecution?.repairUnit?.address"
                      >-</span
                    >
                  </p>
                </div>
              </div>
            </div>
          </div>
        </mat-tab>

        <mat-tab label="Opis naprawy">
          <div class="card">
            <div class="card-content timeline-content">
              <div class="right-site">
                <div class="content-item">
                  <p class="gray">Akcja serwisu</p>
                  <p>
                    {{ order?.orderExecution?.code | dashIfEmpty }} -
                    {{ order?.orderExecution?.action | dashIfEmpty }}
                  </p>
                </div>
              </div>
              <div class="left-site">
                <div class="content-item">
                  <p class="gray">Opis</p>
                  <p>
                    {{ order?.orderExecution?.repairDescription | dashIfEmpty }}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </mat-tab>
      </mat-tab-group>
    </mat-card>
    <mat-card class="card">
      <mat-tab-group mat-stretch-tabs="false" dynamicHeight>
        <mat-tab label="Sprzęt">
          <div class="card">
            <div class="card-content">
              <div class="left-site">
                <div class="content-item">
                  <p class="gray">Nazwa</p>
                  <p>{{ order?.device?.name | dashIfEmpty }}</p>
                </div>
                <hr class="custom-hr" />
                <div class="content-item">
                  <p class="gray">Producent</p>
                  <p>{{ order?.device?.producer | dashIfEmpty }}</p>
                </div>
                <hr class="custom-hr" />
                <div class="content-item">
                  <p>Model</p>
                  <p>{{ order?.device?.model | dashIfEmpty }}</p>
                </div>
              </div>
              <div class="right-site">
                <div class="content-item">
                  <p class="gray">Numer fabryczny</p>
                  <p>{{ order?.device?.serialNumber | dashIfEmpty }}</p>
                </div>
                <hr class="custom-hr" />
                <div class="content-item">
                  <p class="gray">Kod asortymentu</p>
                  <p>{{ order?.device?.assortmentGroup | dashIfEmpty }}</p>
                </div>
              </div>
            </div>
          </div>
        </mat-tab>
        <mat-tab label="Gwarancja">
          <div class="card">
            <div class="card-content">
              <div class="left-site">
                <div class="content-item">
                  <p class="gray">Data zakupu</p>
                  <p>
                    {{
                      order?.device?.purchaseDate
                        | date: 'YYYY-MM-dd'
                        | dashIfEmpty
                    }}
                  </p>
                </div>
                <hr class="custom-hr" />
                <div class="content-item">
                  <p class="gray">Nr faktury/paragonu</p>
                  <p>{{ order?.warranty?.invoiceNo | dashIfEmpty }}</p>
                </div>
              </div>
              <div class="right-site">
                <div class="content-item">
                  <p class="gray">Nr gwarancji</p>
                  <p>{{ order?.warranty?.number | dashIfEmpty }}</p>
                </div>
                <hr class="custom-hr" />
                <div class="content-item">
                  <p class="gray">Cena zakupu / Wartość</p>
                  <p>{{ order?.warranty?.price | dashIfEmpty }}</p>
                </div>
              </div>
            </div>
          </div>
        </mat-tab>
      </mat-tab-group>
    </mat-card>
    <mat-card class="card">
      <div class="card-title show-data-button">
        <button *ngIf="!showData" (click)="showDataModal()" mat-button>
          <mat-icon>visibility</mat-icon> Pokaż
        </button>
        <button *ngIf="showData" (click)="showData = false" mat-button>
          <mat-icon>visibility_off</mat-icon> Ukryj
        </button>
      </div>
      <mat-tab-group mat-stretch-tabs="false" dynamicHeight>
        <mat-tab label="Ubezpieczony">
          <div class="card">
            <div class="card-content">
              <div class="left-site">
                <div class="content-item">
                  <p class="gray">Rodzaj ubezpieczonego</p>
                  <p>
                    <span *ngIf="!showData">********</span>
                    <span *ngIf="showData">
                      {{
                        order?.customer?.contactInformation?.taxType
                          | translate
                          | dashIfEmpty
                      }}</span
                    >
                  </p>
                </div>
                <hr class="custom-hr" />
                <div class="content-item">
                  <p class="gray">Nazwa firmy</p>
                  <p>
                    <span *ngIf="!showData">********</span
                    ><span *ngIf="showData">{{
                      order?.customer?.contactInformation?.companyName
                        | dashIfEmpty
                    }}</span>
                  </p>
                </div>
                <hr class="custom-hr" />
                <div class="content-item">
                  <p class="gray">Miejscowość</p>
                  <p>
                    <span *ngIf="!showData">********</span
                    ><span *ngIf="showData">{{
                      order?.customer?.address?.city | dashIfEmpty
                    }}</span>
                  </p>
                </div>
                <hr class="custom-hr" />
                <div class="content-item">
                  <p class="gray">Ulica</p>
                  <p>
                    <span *ngIf="!showData">********</span
                    ><span *ngIf="showData">{{
                      order?.customer?.address?.streetName | dashIfEmpty
                    }}</span>
                  </p>
                </div>
                <hr class="custom-hr" />
                <div class="content-item">
                  <p class="gray">E-mail</p>
                  <p>
                    <span *ngIf="!showData">********</span
                    ><span *ngIf="showData">{{
                      order?.customer?.contactInformation?.email | dashIfEmpty
                    }}</span>
                  </p>
                </div>
              </div>
              <div class="right-site">
                <div class="content-item">
                  <p class="gray">Imię i nazwisko</p>
                  <p>
                    <span *ngIf="!showData">********</span
                    ><span *ngIf="showData">{{
                      order?.customer?.firstName + ' ' | dashIfEmpty
                    }}</span>
                    <span *ngIf="showData">
                      {{ order?.customer?.lastName }}</span
                    >
                  </p>
                </div>

                <hr class="custom-hr" />
                <div class="content-item">
                  <p class="gray">NIP</p>
                  <p>
                    <span *ngIf="!showData">********</span
                    ><span *ngIf="showData">{{
                      order?.customer?.contactInformation?.nip | dashIfEmpty
                    }}</span>
                  </p>
                </div>
                <hr class="custom-hr" />
                <div class="content-item">
                  <p class="gray">Kod pocztowy</p>
                  <p>
                    <span *ngIf="!showData">********</span
                    ><span *ngIf="showData">{{
                      order?.customer?.address?.postalCode | dashIfEmpty
                    }}</span>
                  </p>
                </div>
                <hr class="custom-hr" />
                <div class="content-item">
                  <p class="gray">Nr domu / nr lokalu</p>
                  <p>
                    <span *ngIf="!showData">********</span
                    ><span *ngIf="showData"
                      >{{
                        order?.customer?.address?.buildingNumber | dashIfEmpty
                      }}
                      /
                      {{
                        order?.customer?.address?.apartmentNumber | dashIfEmpty
                      }}</span
                    >
                  </p>
                </div>
                <hr class="custom-hr" />
                <div class="content-item">
                  <p class="gray">Telefon</p>
                  <p>
                    <span *ngIf="!showData">********</span
                    ><span *ngIf="showData">{{
                      order?.customer?.contactInformation?.mobileNumber
                        | dashIfEmpty
                    }}</span>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </mat-tab>
        <mat-tab label="Polisa">
          <div class="card">
            <div class="card-content">
              <div class="left-site">
                <div class="content-item">
                  <p class="gray">Nr polisy</p>
                  <p>
                    <span *ngIf="!showData">********</span
                    ><span *ngIf="showData">{{
                      order?.insuranceNumber | dashIfEmpty
                    }}</span>
                  </p>
                </div>
                <hr class="custom-hr" />
                <div class="content-item">
                  <p class="gray">Wartość</p>
                  <p>
                    <span *ngIf="!showData">********</span
                    ><span *ngIf="showData">{{
                      order?.device.price
                        | currency: 'PLN' : 'symbol' : '1.2' : 'pl'
                        | dashIfEmpty
                    }}</span>
                  </p>
                </div>
                <hr class="custom-hr" />
                <div class="content-item">
                  <p class="gray">Produkt</p>
                  <p>
                    <span *ngIf="!showData">********</span
                    ><span *ngIf="showData">{{
                      order?.device.model | dashIfEmpty
                    }}</span>
                  </p>
                </div>
                <hr class="custom-hr" />
              </div>
              <div class="right-site">
                <div class="content-item">
                  <p class="gray">Data wystawienia</p>
                  <p>
                    <span *ngIf="!showData">********</span
                    ><span *ngIf="showData">{{
                      order?.warranty?.startDate
                        | date: 'YYYY-MM-dd '
                        | dashIfEmpty
                    }}</span>
                  </p>
                </div>
                <hr class="custom-hr" />
                <div class="content-item">
                  <p class="gray">Kwota dostępna</p>
                  <p>
                    <span *ngIf="!showData">********</span
                    ><span
                      *ngIf="showData && order?.incident.maxinsured > -1"
                      >{{
                        order?.incident.maxinsured
                          | currency: 'PLN' : 'symbol' : '1.2' : 'pl'
                          | dashIfEmpty
                      }}</span
                    >
                    <span *ngIf="showData && order?.incident.maxinsured == -1"
                      >Kwota niedostępna</span
                    >
                  </p>
                </div>
                <hr class="custom-hr" />
                <div class="content-item">
                  <p class="gray">Okres ubezpieczenia</p>
                  <p>
                    <span *ngIf="!showData">********</span
                    ><span *ngIf="showData">{{
                      order?.insurancePeriod | dashIfEmpty
                    }}</span>
                  </p>
                </div>
                <hr class="custom-hr" />
                <div class="content-item">
                  <p class="gray">Udział własny</p>
                  <p>
                    <span *ngIf="!showData">********</span>
                    <span *ngIf="showData">
                      {{ policy?.deductiblePercent | percent | dashIfEmpty }}
                    </span>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </mat-tab>
      </mat-tab-group>
    </mat-card>
  </mat-card>
  <mat-card class="joined-cards">
    <mat-card>
      <div class="card-title show-data-button">
        <button
          (click)="addComment()"
          mat-stroked-button
          *ngIf="
            Auth.isViewAllowed([
              'ROLE_ORDER_DETAILS_EDIT',
              'ROLE_ORDER_COMMENT_ADD',
              'ROLE_ADMIN',
            ])
          ">
          {{ 'orders-front.add_comment' | translate }}
        </button>
      </div>
      <mat-tab-group
        class="no_pointer"
        mat-stretch-tabs="false"
        dynamicHeight
        disableRipple
        disabled>
        <mat-tab label="Komentarze">
          <div class="timeline-card">
            <div class="viewport-comments">
              <mat-card
                class="card row_2 no_shadow"
                *ngFor="let activity of comments; first as isFirst"
                [ngClass]="activity.eventData?.createdBy"
                [ngClass]="{ 'first': isFirst }">
                <div class="left-s icon-header">
                  <mat-icon
                    *ngIf="activity?.eventData?.type == 'drs'"
                    class="black-icon"
                    >home</mat-icon
                  >
                  <mat-icon
                    *ngIf="activity?.eventData?.type == 'provider'"
                    class="blue-icon"
                    >support_agent</mat-icon
                  >
                  <mat-icon
                    *ngIf="activity?.eventData?.type == 'partner'"
                    class="orange-icon"
                    >build</mat-icon
                  >
                </div>
                <div class="right-s">
                  <p class="timestamp_h">
                    <span>{{
                      activity?.eventData?.type | translate | dashIfEmpty
                    }}</span>
                    <span *ngIf="activity?.createdBy">
                      {{
                        activity?.createdBy === 'Unknown'
                          ? '- Wagas'
                          : '- ' + activity?.createdBy
                      }}</span
                    >
                  </p>

                  <p class="comment-content">
                    <strong>{{
                      activity?.eventData?.content | dashIfEmpty
                    }}</strong>
                  </p>
                  <p class="timestamp_h">
                    {{
                      activity?.eventDate
                        | date: 'YYYY-MM-dd HH:mm'
                        | dashIfEmpty
                    }}
                  </p>
                </div>
              </mat-card>
              <h4 class="center" *ngIf="commentsEmpty">Brak danych</h4>
            </div>
          </div>
        </mat-tab>
      </mat-tab-group>
    </mat-card>
    <mat-card>
      <mat-tab-group
        class="no_pointer"
        mat-stretch-tabs="false"
        dynamicHeight
        disableRipple
        disabled>
        <mat-tab label="Historia zgłoszenia">
          <div class="timeline-card">
            <div class="card-content timeline-content">
              <ngx-timeline
                [langCode]="'pl'"
                [events]="events"
                [enableAnimation]="false"
                [periodCustomTemplate]="periodCustomTemplate"
                [eventCustomTemplate]="eventCustomTemplate"
                [centerIconCustomTemplate]="centerIconCustomTemplate"
                [reverseOrder]="true"
                [dateInstantCustomTemplate]="
                  dateInstantCustomTemplate
                "></ngx-timeline>
            </div>
            <!-- [centerIconCustomTemplate]="centerIconCustomTemplate" -->
          </div>
        </mat-tab>
      </mat-tab-group>
    </mat-card>
  </mat-card>
</div>

<!-- <ng-template #centerIconCustomTemplate let-event=event let-index=index>
  <div class="icon-container">
    <mat-icon>build</mat-icon>

  </div>
</ng-template> -->

<ng-template #dateInstantCustomTemplate let-item="item">
  <span>
    <p class="timestamp">{{ item?.timestamp | date: 'YYYY-MM-dd' }}</p>
    <p class="timestamp_h">{{ item?.timestamp | date: 'HH:mm' }}</p>
  </span>
</ng-template>

<ng-template #periodCustomTemplate let-period="period" let-index="index">
  <div [ngClass]="{ 'transparent first-line': !index }" class="line"></div>
  <div class="period-container">
    <div _ngcontent-ng-c67772640="" class="line ng-star-inserted"></div>
  </div>
  <div class="line"></div>
</ng-template>

<ng-template
  #centerIconCustomTemplate
  let-period="period"
  let-index="index"
  let-event="event">
  <div class="icon-container">
    <mat-icon class="timeline-icon" [ngClass]="event?.eventInfo?.alertType"
      >panorama_fish_eye</mat-icon
    >
  </div>
</ng-template>

<ng-template #eventCustomTemplate let-event="event" let-colSidePosition="left">
  <div class="event">
    <div class="event-info-container">
      <div class="event-info-header">
        <div class="title-container">
          <h4>{{ event?.eventInfo?.title }}</h4>
        </div>
      </div>
      <div
        class="event-info-body"
        *ngIf="Auth.isViewAllowed(['ROLE_DETAILS_VIEW', 'ROLE_ADMIN'])">
        <h4>
          <p class="event-info-description">
            {{ event?.eventInfo?.description }}
            <!-- <span [innerHTML]="event?.eventInfo?.extraData"></span> -->
          </p>
        </h4>
      </div>
    </div>
  </div>
</ng-template>
