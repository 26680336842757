import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormArray, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { OrdersService } from 'src/app/pages/orders/services/orders.service';

@Component({
  selector: 'app-edit-parts',
  templateUrl: './edit-parts.component.html',
  styleUrls: ['./edit-parts.component.scss'],
})
export class EditPartsComponent implements OnInit {
  partsForm: FormGroup;
  order: any;
  error: any;
  synchro: any = false;

  constructor(
    private fb: FormBuilder,
    private ordersService: OrdersService,
    private dialogRef: MatDialogRef<EditPartsComponent>,
    private _snackBar: MatSnackBar,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.order = data.order;
  }

  ngOnInit(): void {
    this.partsForm = this.fb.group({
      parts: this.fb.array([]),
    });

    // Load existing parts data
    if (this.order && this.order.parts && this.order.parts.length > 0) {
      this.order.parts.forEach((part: any) => {
        this.parts.push(this.createPart(part));
      });
    } else {
      // Initialize with one empty part if no parts data is available
      this.parts.push(this.createPart());
    }
    console.log(this.parts);
    if (this.parts.length == 0) {
      this.parts.push(this.createPart());
    }
  }

  get parts(): FormArray {
    return this.partsForm.get('parts') as FormArray;
  }

  createPart(part: any = {}): FormGroup {
    return this.fb.group({
      name: [part.name || '', Validators.required],
      partNumber: [part.partNumber || ''],
      quantity: [part.quantity || ''],
      price: [part.price || ''],
    });
  }

  addPart(): void {
    this.parts.push(this.createPart());
  }

  removePart(index: number): void {
    if (this.parts.length > 1) {
      this.parts.removeAt(index);
    } else {
      this.clearPart(index);
    }
  }

  clearPart(index: number): void {
    const part = this.parts.at(index) as FormGroup;
    part.reset({
      name: '',
      partNumber: '',
      quantity: '',
      price: '',
    });
  }

  onSubmit(): void {
    let data = this.partsForm.value.parts;
    console.log(data);

    // Check if there is only one part and its values are all zero

    if (data.length === 1) {
      const part = data[0];
      if (
        part.name === '' &&
        part.partNumber === '' &&
        part.quantity === '' &&
        part.price === ''
      ) {
        data = [];
      }
    }
    data = data.map((part: any) => {
      return {
        name: part.name,
        partNumber: part.partNumber,
        quantity: part.quantity,
        price: part.price,
      };
    });
    this.ordersService.editParts(this.order.id, data, this.synchro).subscribe(
      success => {
        this._snackBar.open('Koszty zostały zaktualizowane', 'Zamknij', {});
        this.dialogRef.close({
          result: 'success',
        });
      },
      error => {
        console.log(error);
        this.error = error;
      }
    );
  }
}
