import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { AuthGuard } from 'dashboard-libs/guards/access-allowed.guard';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-add-preauth-modal-modal',
  templateUrl: './add-preauth-modal.component.html',
  styleUrls: ['./add-preauth-modal.component.scss'],
})
export class AddPreauthModalComponent implements OnInit {
  preauthForm: FormGroup;

  constructor(
    private dialogRef: MatDialogRef<AddPreauthModalComponent>,
    public Auth: AuthGuard,
    private fb: FormBuilder
  ) {}

  ngOnInit() {
    this.preauthForm = this.fb.group({
      code: ['', Validators.required],
      action: ['', Validators.required],
      diagnosis: ['', Validators.required],
      actionCode: ['', Validators.required],
      estimatedCost: [
        '',
        [Validators.required, Validators.pattern(/^\d+(\.\d{1,2})?$/)],
      ],
    });
  }

  onCancel() {
    this.dialogRef.close();
  }

  onSubmit() {
    if (this.preauthForm.valid) {
      this.dialogRef.close(this.preauthForm.value);
    }
  }
}
