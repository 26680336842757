<h1 mat-dialog-title class="title">Edycja części</h1>
<hr class="custom-hr" />
<div mat-dialog-content>
  <form [formGroup]="partsForm" (ngSubmit)="onSubmit()">
    <div formArrayName="parts">
      <div
        class="types"
        *ngFor="let part of parts.controls; let i = index"
        [formGroupName]="i">
        <div class="did-floating-label-content">
          <input
            class="did-floating-input"
            formControlName="partNumber"
            type="text"
            placeholder=" " />
          <label class="did-floating-label">Numer części</label>
        </div>
        <div class="did-floating-label-content">
          <input
            class="did-floating-input"
            formControlName="name"
            type="text"
            placeholder=" " />
          <label class="did-floating-label">Nazwa częsci</label>
        </div>

        <div class="did-floating-label-content">
          <input
            class="did-floating-input"
            formControlName="quantity"
            type="number"
            placeholder=" " />
          <label class="did-floating-label">Ilość</label>
        </div>
        <div class="did-floating-label-content">
          <input
            class="did-floating-input"
            formControlName="price"
            type="number"
            placeholder=" " />
          <label class="did-floating-label">Koszt</label>
        </div>
        <div class="did-floating-label-content">
          <button type="button" mat-button (click)="removePart(i)">Usuń</button>
        </div>
      </div>
    </div>

    <button type="button" mat-button (click)="addPart()">
      Dodaj kolejną część
    </button>
  </form>
</div>
<section class="example-section">
  <mat-checkbox [(ngModel)]="synchro" class="example-margin"
    >Włącz synchronizacje</mat-checkbox
  >
</section>

<mat-error *ngIf="error" class="error"> {{ error }}</mat-error>
<div mat-dialog-actions>
  <button
    mat-button
    class="secondary-btn"
    color="accent"
    [mat-dialog-close]="'cancel'">
    {{ 'orders-front.cancel' | translate }}
  </button>
  <button mat-button class="primary-btn" (click)="onSubmit()" color="primary">
    Zapisz
  </button>
</div>
