<h1 mat-dialog-title class="text-left" *ngIf="!confirmDialog">
  Preautoryzacja
</h1>
<hr class="custom-hr" />
<div mat-dialog-content *ngIf="!confirmDialog">
  <mat-icon class="close-button" [mat-dialog-close]="'cancel'">close</mat-icon>

  <mat-card
    class="title-card waiting"
    *ngIf="
      data?.preAuth && !data?.preAuth?.decision && data?.preAuth?.requestedAt
    ">
    <mat-card-title
      ><mat-icon>access_time</mat-icon>Oczekuje na decyzję</mat-card-title
    >

    <mat-card-content
      >Autoryzacja została przesłana do podmiotu akceptującego</mat-card-content
    >
  </mat-card>
  <mat-card
    class="title-card rejected"
    *ngIf="data.preAuth.decision == 'ODRZUCONO'">
    <mat-card-title><mat-icon>error_outline</mat-icon>Odmowa</mat-card-title>

    <mat-card-content>
      Autoroyzacja została odrzucona przez podmiot
      akceptujący.</mat-card-content
    >
  </mat-card>
  <mat-card
    class="title-card accepted"
    *ngIf="data.preAuth.decision == 'AKCEPTACJA'">
    <mat-card-title>
      <mat-icon>check_circle_outline</mat-icon>Akceptacja</mat-card-title
    >

    <mat-card-content
      >Autoroyzacja została zaakceptowana przez podmiot
      akceptujący.</mat-card-content
    >
  </mat-card>
  <mat-card
    class="title-card total-damage"
    *ngIf="data.preAuth.decision == 'SZKODA CAŁKOWITA'">
    <mat-card-title>
      <mat-icon>restore_from_trash</mat-icon>
      <h3>Szkoda całkowita</h3></mat-card-title
    >

    <mat-card-content
      >Autoroyzacja uzyskała decyzję o szkodzi całkowitej.</mat-card-content
    >
  </mat-card>

  <mat-card class="card">
    <div class="order-info-panel">
      <div>
        <p>{{ 'preAuth.added_at' | translate }}</p>
        <p>
          {{ data.preAuth.createdAt | date: 'YYYY-MM-dd HH:mm' | dashIfEmpty }}
        </p>
      </div>
      <hr />
      <div>
        <p>{{ 'auth_code' | translate }}</p>
        <p *ngIf="data.preAuth.code == 'W'">
          W - Wycena / Naprawa możliwa droga
        </p>
        <p *ngIf="data.preAuth.code == 'N'">N - Naprawa niemożliwa</p>
      </div>
      <hr />
      <div>
        <p>{{ 'preAuth.diagnosis' | translate }}</p>
        <p>{{ data.preAuth.diagnosis | dashIfEmpty }}</p>
      </div>
      <hr />
      <div>
        <p>{{ 'preAuth.cost' | translate }}</p>
        <p>{{ data.preAuth.estimatedCost | dashIfEmpty }}</p>
      </div>
      <hr />
      <div>
        <p>{{ 'preAuth.reason_of_damage' | translate }}</p>

        <p>
          {{ data.preAuth.actionCode }} -
          {{ data.preAuth.action }}
        </p>
      </div>
      <hr />
      <div>
        <p>{{ 'preAuth.decision_data' | translate }}</p>
        <p>
          {{
            data.preAuth.preauthorizedAt
              | date: 'YYYY-MM-dd HH:mm'
              | dashIfEmpty
          }}
        </p>
      </div>
    </div>
  </mat-card>
</div>
<div mat-dialog-actions *ngIf="confirmDialog">
  <mat-card class="card center">
    <div mat-dialog-title>Decyzja: {{ action | translate }}</div>
    <div>Czy potwierdzasz wybór ?</div>
  </mat-card>
</div>
<div
  mat-dialog-actions
  *ngIf="
    Auth.isViewAllowed(['ROLE_ADMIN']) &&
    !confirmDialog &&
    data?.preAuth &&
    !data?.preAuth?.decision &&
    data?.preAuth?.requestedAt
  ">
  <button mat-flat-button (click)="confirm('total damage')" class="gray">
    Szkoda całkowita
  </button>
  <button mat-flat-button (click)="confirm('reject')" class="red">
    Odrzuć
  </button>
  <button mat-flat-button (click)="confirm('accept')" class="green">
    Akceptuj
  </button>
</div>
<div mat-dialog-actions *ngIf="confirmDialog">
  <button mat-stroked-button (click)="cancel()">Anuluj</button>
  <button mat-flat-button class="blue" (click)="send()">Potwierdz</button>
</div>
