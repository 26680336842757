<div
  class="main-content"
  *ngIf="Auth.isViewAllowed(['ROLE_BILLINGS_VIEW', 'ROLE_ADMIN'])">
  <mat-card>
    <div class="card">
      <div class="card-title">
        <div>Koszty</div>
        <div class="slider">
          <mat-slide-toggle
            *ngIf="Auth.isViewAllowed(['ROLE_ORDER_MARGIN_VIEW', 'ROLE_ADMIN'])"
            [(ngModel)]="details"
            >{{ 'Details' | translate }}</mat-slide-toggle
          >
        </div>
      </div>
      <hr class="custom-hr" />
      <div class="card-content" *ngIf="order?.costs">
        <div class="content-item">
          <div class="left-site">
            <p class="bold">Nazwa kosztu</p>
            <p>Koszt transportu</p>
            <ul>
              <li><p>Koszt kuriera</p></li>
              <li><p>Koszt obsługi</p></li>
            </ul>
            <p>Całkowity kosz ekspertyzy</p>
            <ul>
              <li><p>Koszt konsultacji</p></li>
              <li><p>Koszt wymiany</p></li>
            </ul>
            <p>Całkowity koszt części</p>

            <p>Koszt robocizny</p>
          </div>
          <div class="right-site">
            <p>
              <label class="text-blue bold" *ngIf="details">Cena | </label>

              <label class="text-green bold" *ngIf="details">Marża | </label>

              <label class="text-red bold" *ngIf="details">Opł. serw. | </label>
              <label class="bold">Suma</label>
            </p>
            <p>
              <label class="text-blue" *ngIf="details"
                >{{
                  (order?.costs?.courier || 0) + (order?.costs?.service || 0) ||
                    0
                    | currency: 'PLN' : 'symbol' : '1.2' : 'pl'
                    | dashIfEmpty
                }}
                |
              </label>
              <label class="text-green" *ngIf="details"
                >+{{
                  (order?.costs?.courierMarginValue || 0) +
                    (order?.costs?.serviceMarginValue || 0) || 0
                    | currency: 'PLN' : 'symbol' : '1.2' : 'pl'
                    | dashIfEmpty
                }}
                |
              </label>
              <label class="text-red" *ngIf="details"
                >+{{
                  (order?.costs?.courierServiceFeeValue || 0) +
                    (order?.costs?.serviceServiceFeeValue || 0) || 0
                    | currency: 'PLN' : 'symbol' : '1.2' : 'pl'
                    | dashIfEmpty
                }}
                |
              </label>
              {{
                (order?.costs?.courierWithMarginAndServiceFee || 0) +
                  (order?.costs?.serviceWithMarginAndServiceFee || 0) || 0
                  | currency: 'PLN' : 'symbol' : '1.2' : 'pl'
                  | dashIfEmpty
              }}
            </p>

            <p>
              <!-- <label class="text-orange" *ngIf="details"
                >{{
                  order?.costs?.courierMarginPercent / 100 || 0
                    | percent : '1.2-2'
                    | dashIfEmpty
                }}
                |
              </label> -->
              <label class="text-blue" *ngIf="details"
                >{{
                  order?.costs?.courier || 0
                    | currency: 'PLN' : 'symbol' : '1.2' : 'pl'
                    | dashIfEmpty
                }}
                |
              </label>
              <label class="text-green" *ngIf="details"
                >+{{
                  order?.costs?.courierMarginValue || 0
                    | currency: 'PLN' : 'symbol' : '1.2' : 'pl'
                    | dashIfEmpty
                }}
                |
              </label>
              <label class="text-red" *ngIf="details"
                >+{{
                  order?.costs?.courierServiceFeeValue || 0
                    | currency: 'PLN' : 'symbol' : '1.2' : 'pl'
                    | dashIfEmpty
                }}
                |
              </label>
              <label
                >{{
                  order?.costs?.courierWithMarginAndServiceFee || 0
                    | currency: 'PLN' : 'symbol' : '1.2' : 'pl'
                    | dashIfEmpty
                }}
              </label>
            </p>
            <p>
              <!-- <label class="text-orange" *ngIf="details"
                >{{
                  order?.costs?.serviceMarginPercent / 100 || 0
                    | percent : '1.2-2'
                    | dashIfEmpty
                }}
                |
              </label> -->
              <label class="text-blue" *ngIf="details">
                {{
                  order?.costs?.service || 0
                    | currency: 'PLN' : 'symbol' : '1.2' : 'pl'
                    | dashIfEmpty
                }}
                |
              </label>
              <label class="text-green" *ngIf="details">
                +
                {{
                  order?.costs?.serviceMarginValue || 0
                    | currency: 'PLN' : 'symbol' : '1.2' : 'pl'
                    | dashIfEmpty
                }}
                |
              </label>
              <label class="text-red" *ngIf="details"
                >+{{
                  order?.costs?.serviceServiceFeeValue || 0
                    | currency: 'PLN' : 'symbol' : '1.2' : 'pl'
                    | dashIfEmpty
                }}
                |
              </label>
              <label>
                {{
                  order?.costs?.serviceWithMarginAndServiceFee || 0
                    | currency: 'PLN' : 'symbol' : '1.2' : 'pl'
                    | dashIfEmpty
                }}
              </label>
            </p>

            <p>
              <label class="text-blue" *ngIf="details"
                >{{
                  (order?.costs?.consultation || 0) +
                    (order?.costs?.replacement || 0) || 0
                    | currency: 'PLN' : 'symbol' : '1.2' : 'pl'
                    | dashIfEmpty
                }}
                |
              </label>
              <label class="text-green" *ngIf="details"
                >+{{
                  (order?.costs?.consultationMarginValue || 0) +
                    (order?.costs?.replacementMarginValue || 0) || 0
                    | currency: 'PLN' : 'symbol' : '1.2' : 'pl'
                    | dashIfEmpty
                }}
                |
              </label>
              <label class="text-red" *ngIf="details"
                >+{{
                  (order?.costs?.consultationServiceFeeValue || 0) +
                    (order?.costs?.replacementServiceFeeValue || 0) || 0
                    | currency: 'PLN' : 'symbol' : '1.2' : 'pl'
                    | dashIfEmpty
                }}
                |
              </label>
              {{
                (order?.costs?.consultationWithMarginAndServiceFee || 0) +
                  (order?.costs?.replacementWithMarginAndServiceFee || 0) || 0
                  | currency: 'PLN' : 'symbol' : '1.2' : 'pl'
                  | dashIfEmpty
              }}
            </p>
            <p>
              <!-- <label class="text-orange" *ngIf="details"
                >{{
                  order?.costs?.consultationMarginPercent / 100 || 0
                    | percent : '1.2-2'
                    | dashIfEmpty
                }}
                |
              </label> -->
              <label class="text-blue" *ngIf="details">
                {{
                  order?.costs?.consultation || 0
                    | currency: 'PLN' : 'symbol' : '1.2' : 'pl'
                    | dashIfEmpty
                }}
                |
              </label>
              <label class="text-green" *ngIf="details">
                +{{
                  order?.costs?.consultationMarginValue || 0
                    | currency: 'PLN' : 'symbol' : '1.2' : 'pl'
                    | dashIfEmpty
                }}
                |
              </label>

              <label class="text-red" *ngIf="details"
                >+{{
                  order?.costs?.consultationServiceFeeValue || 0
                    | currency: 'PLN' : 'symbol' : '1.2' : 'pl'
                    | dashIfEmpty
                }}
                |
              </label>
              {{
                order?.costs?.consultationWithMarginAndServiceFee || 0
                  | currency: 'PLN' : 'symbol' : '1.2' : 'pl'
                  | dashIfEmpty
              }}
            </p>
            <p>
              <!-- <label class="text-orange" *ngIf="details"
                >{{
                  order?.costs?.replacementMarginPercent / 100 || 0
                    | percent : '1.2-2'
                    | dashIfEmpty
                }}
                |
              </label> -->
              <label class="text-blue" *ngIf="details">
                {{
                  order?.costs?.replacement || 0
                    | currency: 'PLN' : 'symbol' : '1.2' : 'pl'
                    | dashIfEmpty
                }}
                |
              </label>
              <label class="text-green" *ngIf="details">
                +{{
                  order?.costs?.replacementMarginValue || 0
                    | currency: 'PLN' : 'symbol' : '1.2' : 'pl'
                    | dashIfEmpty
                }}
                |
              </label>
              <label class="text-red" *ngIf="details"
                >+{{
                  order?.costs?.replacementServiceFeeValue || 0
                    | currency: 'PLN' : 'symbol' : '1.2' : 'pl'
                    | dashIfEmpty
                }}
                |
              </label>
              <label>
                {{
                  order?.costs?.replacementWithMarginAndServiceFee || 0
                    | currency: 'PLN' : 'symbol' : '1.2' : 'pl'
                    | dashIfEmpty
                }}
              </label>
            </p>
            <p>
              <!-- <label class="text-orange" *ngIf="details"
                >{{
                  order?.costs?.partsMarginPercent / 100 || 0
                    | percent : '1.2-2'
                    | dashIfEmpty
                }}
                |
              </label> -->
              <label class="text-blue" *ngIf="details">
                {{
                  order?.costs?.parts || 0
                    | currency: 'PLN' : 'symbol' : '1.2' : 'pl'
                    | dashIfEmpty
                }}
                |
              </label>
              <label class="text-green" *ngIf="details">
                +{{
                  order?.costs?.partsMarginValue || 0
                    | currency: 'PLN' : 'symbol' : '1.2' : 'pl'
                    | dashIfEmpty
                }}
                |
              </label>
              <label class="text-red" *ngIf="details"
                >+{{
                  order?.costs?.partsServiceFeeValue || 0
                    | currency: 'PLN' : 'symbol' : '1.2' : 'pl'
                    | dashIfEmpty
                }}
                |
              </label>
              <label>
                {{
                  order?.costs?.partsWithMarginAndServiceFee || 0
                    | currency: 'PLN' : 'symbol' : '1.2' : 'pl'
                    | dashIfEmpty
                }}
              </label>
            </p>

            <p>
              <!-- <label class="text-orange" *ngIf="details"
                >{{
                  order?.costs?.repairMarginPercent / 100 || 0
                    | percent : '1.2-2'
                    | dashIfEmpty
                }}
                |
              </label> -->
              <label class="text-blue" *ngIf="details">
                {{
                  order?.costs?.repair || 0
                    | currency: 'PLN' : 'symbol' : '1.2' : 'pl'
                    | dashIfEmpty
                }}
                |
              </label>
              <label class="text-green" *ngIf="details">
                +
                {{
                  order?.costs?.repairMarginValue || 0
                    | currency: 'PLN' : 'symbol' : '1.2' : 'pl'
                    | dashIfEmpty
                }}
                |
              </label>
              <label class="text-red" *ngIf="details"
                >+{{
                  order?.costs?.repairServiceFeeValue || 0
                    | currency: 'PLN' : 'symbol' : '1.2' : 'pl'
                    | dashIfEmpty
                }}
                |
              </label>
              {{
                order?.costs?.repairWithMarginAndServiceFee || 0
                  | currency: 'PLN' : 'symbol' : '1.2' : 'pl'
                  | dashIfEmpty
              }}
            </p>
          </div>
        </div>
        <div class="content-item">
          <div class="left-site">
            <p class="bold">Podsumowanie</p>
            <p>Udział własny %</p>
            <p>Udział własny w szkodzie :</p>

            <h4>Wartość zlecenia netto :</h4>
            <h4>Wartość zlecenia brutto :</h4>
          </div>
          <div class="right-site">
            <p>
              <label class="bold text-blue" *ngIf="details"
                >Koszt serwisu |
              </label>
              <label class="bold">Koszt DRS</label>
            </p>
            <p>
              {{
                order?.costs?.deductibleInsurancePercent * 0.01 || 0
                  | percent: '1.2-2'
              }}
            </p>

            <p>
              <label class="text-blue" *ngIf="details"
                >{{
                  order?.costs?.deductibleWithMargin || 0
                    | currency: 'PLN' : 'symbol' : '1.2' : 'pl'
                    | dashIfEmpty
                }}
                |
              </label>
              {{
                order?.costs?.deductibleValueByInsurancePercent || 0
                  | currency: 'PLN' : 'symbol' : '1.2' : 'pl'
                  | dashIfEmpty
              }}
            </p>

            <h4>
              <label class="text-blue" *ngIf="details"
                >{{
                  order?.costs?.totalNet || 0
                    | currency: 'PLN' : 'symbol' : '1.2' : 'pl'
                    | dashIfEmpty
                }}
                |
              </label>
              {{
                order?.costs?.totalNetWithMarginAndServiceFee || 0
                  | currency: 'PLN' : 'symbol' : '1.2' : 'pl'
                  | dashIfEmpty
              }}
            </h4>

            <h4>
              <label class="text-blue" *ngIf="details"
                >{{
                  order?.costs?.totalGross || 0
                    | currency: 'PLN' : 'symbol' : '1.2' : 'pl'
                    | dashIfEmpty
                }}
                |
              </label>
              {{
                order?.costs?.totalGrossWithMarginAndServiceFee || 0
                  | currency: 'PLN' : 'symbol' : '1.2' : 'pl'
                  | dashIfEmpty
              }}
            </h4>
          </div>
        </div>
      </div>
      <div class="empty-table-view" *ngIf="!order?.costs">
        <p>{{ 'no_costs' | translate }}</p>
      </div>
    </div>
  </mat-card>
  <mat-card>
    <div class="card">
      <div class="card-title">Części</div>
      <hr class="custom-hr" />
      <div class="card-content">
        <div>
          <p>Id</p>
          <p *ngFor="let part of order?.parts; let i = index">
            <span>{{ i + 1 }}.</span>
          </p>
        </div>
        <div class="of3">
          <p>Nazwa części</p>

          <p *ngFor="let part of order?.parts">{{ part.name | dashIfEmpty }}</p>
        </div>
        <div class="of3">
          <p><span>Ilość</span><span></span> <span>Cena</span></p>
          <p *ngFor="let part of order?.parts">
            <span> {{ part.quantity | dashIfEmpty }}</span>
            <span></span>
            <span>
              <!-- <label class="text-orange" *ngIf="details"
                >{{
                  part?.marginPercent / 100 || 0
                    | percent : '1.2-2'
                    | dashIfEmpty
                }}
                |
              </label> -->
              <label class="text-blue" *ngIf="details">
                {{
                  part?.price || 0
                    | currency: 'PLN' : 'symbol' : '1.2' : 'pl'
                    | dashIfEmpty
                }}
                |
              </label>
              <label class="text-green" *ngIf="details">
                {{
                  part?.marginValue || 0
                    | currency: 'PLN' : 'symbol' : '1.2' : 'pl'
                    | dashIfEmpty
                }}
                |
              </label>
              <label class="text-red" *ngIf="details">
                {{
                  part?.serviceFeeValue || 0
                    | currency: 'PLN' : 'symbol' : '1.2' : 'pl'
                    | dashIfEmpty
                }}
                |
              </label>
              {{
                part?.priceWithMarginAndServiceFee
                  | currency: 'PLN' : 'symbol' : '1.2' : 'pl'
                  | dashIfEmpty
              }}</span
            >
          </p>
        </div>
        <div class="of3">
          <p>Data dodania</p>
          <p *ngFor="let part of order?.parts">
            {{ part.createdAt | date: 'YYYY-MM-dd | HH:mm' | dashIfEmpty }}
          </p>
        </div>
      </div>
      <div class="empty-table-view" *ngIf="order?.parts.length === 0">
        <p>{{ 'no_parts' | translate }}</p>
      </div>
    </div>
  </mat-card>
  <mat-card>
    <div class="card">
      <div class="card-title">Historia kosztów</div>
      <hr class="custom-hr" />

      <table mat-table [dataSource]="dataSource" matSort>
        <ng-container matColumnDef="Lp">
          <th mat-header-cell *matHeaderCellDef>Lp.</th>
          <td
            mat-cell
            *matCellDef="let element; let i = index"
            [ngClass]="{ 'bold': element.id == order?.costs?.id }">
            {{ i + 1 }}
          </td>
        </ng-container>
        <ng-container matColumnDef="date">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Data</th>
          <td
            mat-cell
            *matCellDef="let element"
            [ngClass]="{ 'bold': element.id == order?.costs?.id }">
            {{ element.createdAt | date: 'YYYY-MM-dd HH:mm:ss' | dashIfEmpty }}
          </td>
        </ng-container>
        <ng-container matColumnDef="author">
          <th mat-header-cell *matHeaderCellDef>Autor</th>
          <td
            mat-cell
            *matCellDef="let element"
            [ngClass]="{ 'bold': element.id == order?.costs?.id }">
            {{ element.courierName }}
          </td>
        </ng-container>
        <ng-container matColumnDef="transport_cost">
          <th mat-header-cell *matHeaderCellDef>Koszt transportu</th>
          <td
            mat-cell
            *matCellDef="let element"
            [ngClass]="{ 'bold': element.id == order?.costs?.id }">
            {{
              (element.courierWithMarginAndServiceFee || 0) +
                (element.serviceWithMarginAndServiceFee || 0) || 0
                | currency: 'PLN' : 'symbol' : '1.2' : 'pl'
                | dashIfEmpty
            }}
          </td>
        </ng-container>
        <ng-container matColumnDef="service_cost">
          <th mat-header-cell *matHeaderCellDef>Koszt robocizny</th>
          <td
            mat-cell
            *matCellDef="let element"
            [ngClass]="{ 'bold': element.id == order?.costs?.id }">
            {{
              element.repairWithMarginAndServiceFee || 0
                | currency: 'PLN' : 'symbol' : '1.2' : 'pl'
                | dashIfEmpty
            }}
          </td>
        </ng-container>
        <ng-container matColumnDef="consultation_cost">
          <th mat-header-cell *matHeaderCellDef>Koszt ekspertyzy</th>
          <td
            mat-cell
            *matCellDef="let element"
            [ngClass]="{ 'bold': element.id == order?.costs?.id }">
            {{
              (element.consultationWithMarginAndServiceFee || 0) +
                (element.replacementWithMarginAndServiceFee || 0) || 0
                | currency: 'PLN' : 'symbol' : '1.2' : 'pl'
                | dashIfEmpty
            }}
          </td>
        </ng-container>

        <ng-container matColumnDef="parts_cost">
          <th mat-header-cell *matHeaderCellDef>Koszt części</th>
          <td
            mat-cell
            *matCellDef="let element"
            [ngClass]="{ 'bold': element.id == order?.costs?.id }">
            {{
              element.partsWithMarginAndServiceFee || 0
                | currency: 'PLN' : 'symbol' : '1.2' : 'pl'
                | dashIfEmpty
            }}
          </td>
        </ng-container>

        <ng-container matColumnDef="total_netto">
          <th mat-header-cell *matHeaderCellDef>Razem netto</th>
          <td
            mat-cell
            *matCellDef="let element"
            [ngClass]="{ 'bold': element.id == order?.costs?.id }">
            {{
              element.totalNetWithMarginAndServiceFee || 0
                | currency: 'PLN' : 'symbol' : '1.2' : 'pl'
                | dashIfEmpty
            }}
          </td>
        </ng-container>

        <ng-container matColumnDef="total_brutto">
          <th mat-header-cell *matHeaderCellDef>Razem brutto</th>
          <td
            mat-cell
            *matCellDef="let element"
            [ngClass]="{ 'bold': element.id == order?.costs?.id }">
            {{
              element.totalGrossWithMarginAndServiceFee || 0
                | currency: 'PLN' : 'symbol' : '1.2' : 'pl'
                | dashIfEmpty
            }}
          </td>
        </ng-container>

        <ng-container matColumnDef="own_share">
          <th mat-header-cell *matHeaderCellDef>Udział własny</th>
          <td
            mat-cell
            *matCellDef="let element"
            [ngClass]="{ 'bold': element.id == order?.costs?.id }">
            {{
              element.deductibleValueByInsurancePercent || 0
                | currency: 'PLN' : 'symbol' : '1.2' : 'pl'
                | dashIfEmpty
            }}
          </td>
        </ng-container>

        <ng-container matColumnDef="own_share_percent">
          <th mat-header-cell *matHeaderCellDef>Udział własny %</th>
          <td
            mat-cell
            *matCellDef="let element"
            [ngClass]="{ 'bold': element.id == order?.costs?.id }">
            {{
              element.deductibleInsurancePercent * 0.01 || 0
                | percent
                | dashIfEmpty
            }}
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr
          class="hovered"
          mat-row
          *matRowDef="let row; columns: displayedColumns"></tr>
      </table>
    </div>
  </mat-card>
</div>
